import { useCallback, useState } from "react";
import { WorkerData } from "types";
import dayjs from "dayjs";
import useAuth from "./useAuth";
import { useWorkerService } from "~/services";

export const useWorkerData = () => {
  const { jwt } = useAuth();
  const workerService = useWorkerService();
  const [workerData, setWorkerData] = useState<WorkerData | null>(null);
  const [isBoostActive, setIsBoostActive] = useState<boolean>(false);
  const [boostValidUntil, setBoostValidUntil] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getBoostStatus = useCallback(async (id: string) => {
    if (!id) return;
    workerService
      .fetchBoostStatus(id)
      .then((res) => {
        if (res.status === 200) {
          setIsBoostActive(res.data["valid?"]);
          setBoostValidUntil(dayjs(res.data["valid_until"]).format("DD/MM/YYYY"));
        }
      })
      .catch((e) => console.error("subscription status error: ", e));
  }, []);

  const fetchWorkerData = useCallback(() => {
    if (!jwt) return;
    workerService
      .fetchWorkerData()
      .then((res) => {
        if (res.status === 200) {
          setWorkerData(res.data);
          getBoostStatus(res.data.user_id);
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  }, [getBoostStatus]);

  return {
    isLoading,
    workerData,
    setWorkerData,
    fetchWorkerData,
    isBoostActive,
    boostValidUntil,
    jwt: jwt as string | undefined,
  };
};
