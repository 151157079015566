import { useRouter } from "next/router";
import { ScreenSpinnerWrapper, Spinner, Redirect } from "~/components/Base";
import useAuth from "~/hooks/useAuth";
interface AuthGuardProps {
  children: JSX.Element;
}
const privatePaths = ["/worker/", "/employer/", "/account/", "/referral/"];
export const AuthGuard = ({ children }: AuthGuardProps) => {
  const router = useRouter();
  const { user: userData, isReady, jwt, logoutRedirect } = useAuth();
  const redirectTo = encodeURIComponent(router.pathname);
  const redirectParams = encodeURIComponent(JSON.stringify(router.query));
  const redirectLocation = "/login";
  const signupPath = "/signup";
  const redirectToLogin =
    router.query?.redirectTo &&
    (Array.isArray(router.query.redirectTo)
      ? decodeURIComponent(router.query.redirectTo[0])
      : decodeURIComponent(router.query.redirectTo));
  const redirectParamsLogin =
    router.query?.redirectParams &&
    (Array.isArray(router.query.redirectParams)
      ? JSON.parse(decodeURIComponent(router.query.redirectParams[0]))
      : JSON.parse(decodeURIComponent(router.query.redirectParams)));
  // path without queries
  const path = router.asPath.split("?")[0];
  const isPrivate = privatePaths.filter((privatePath) => path.startsWith(privatePath)).length > 0;

  // check if private route
  if (isPrivate) {
    // private routes
    if (!jwt && !isReady) {
      return (
        <ScreenSpinnerWrapper>
          <Spinner color="violet" />
        </ScreenSpinnerWrapper>
      );
    }

    if (!jwt && isReady) {
      if (logoutRedirect) {
        return <Redirect to="/" />;
      }

      if (!!!redirectParams && !!!redirectTo) {
        return <Redirect to={redirectLocation} />;
      } else {
        const redirectQueryParams =
          decodeURIComponent(redirectParams) === "{}"
            ? new URLSearchParams({ ...router.query, redirectTo })
            : new URLSearchParams({ ...router.query, redirectTo, redirectParams });

        return "user_id" in router.query ? (
          <Redirect to={`${signupPath}?${redirectQueryParams}`} />
        ) : (
          <Redirect to={`${redirectLocation}?${redirectQueryParams}`} />
        );
      }
    }

    if (jwt) {
      if (!isReady || !userData) {
        return (
          <ScreenSpinnerWrapper>
            <Spinner color="violet" />
          </ScreenSpinnerWrapper>
        );
      }

      if (router.asPath.startsWith("/worker/")) {
        if (userData && userData.type === "employer") {
          return <Redirect to="/employer/job-positions" />;
        } else if (userData && userData.type === "ambassador") {
          return <Redirect to="/challenge-wom/posizioni" />;
        } else if (userData && userData.type === "lead") {
          return <Redirect to="/" />;
        }
      }

      if (router.asPath.startsWith("/employer/")) {
        if (userData && userData.type === "worker") {
          return <Redirect to="/worker/dashboard" />;
        } else if (userData && userData.type === "ambassador") {
          return <Redirect to="/challenge-wom/posizioni" />;
        } else if (userData && userData.type === "lead") {
          return <Redirect to="/" />;
        }
      }
      return children;
    }
  }

  // public routes

  if (router.asPath.startsWith("/reset-password")) {
    if (jwt && isReady) {
      return <Redirect to="/worker/dashboard" />;
    }
  }
  if (router.asPath.startsWith("/login") || router.asPath.startsWith("/auth-pages/login")) {
    if (jwt && isReady) {
      if (!!redirectToLogin && !!redirectParamsLogin)
        return <Redirect to={`${redirectToLogin}?${new URLSearchParams(redirectParamsLogin)}`} />;
      else if (!!redirectToLogin) return <Redirect to={redirectToLogin} />;
      else if (userData?.type === "employer") return <Redirect to={"/employer/job-positions"} />;
      else if (userData?.type === "ambassador") return <Redirect to={"/challenge-wom/posizioni"} />;
      else return <Redirect to={"/worker/dashboard"} />;
    }
  }

  return children;
};
