import { createGlobalStyle } from "styled-components";
import tw, { GlobalStyles as BaseStyles } from "twin.macro";

const CustomStyles = createGlobalStyle`
  html {
    ${tw`font-sans cursor-default`}
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
  }

  body {
    ${tw`bg-white text-base text-black antialiased tracking-wide overflow-x-hidden overscroll-y-none`}
  }

  [data-whatintent='mouse'] *:focus,
  [data-whatintent='touch'] *:focus {
    outline: 0 !important;
    box-shadow: none;
  }
  
  .text-pear {
    ${tw`text-pear`}
  }
`;

export const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);
