import { useState, useEffect, useCallback } from "react";
import NextHead from "next/head";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { DefaultSeo, DefaultSeoProps } from "next-seo";
import smoothscroll from "smoothscroll-polyfill";
import NextNprogress from "nextjs-progressbar";
import { Cookies } from "react-cookie-consent";
import { pdfjs } from "react-pdf";
// import ReactGA from "react-ga4";
import { IntercomProvider } from "~/contexts/intercomContext";

import { GlobalStyles } from "~/styles/GlobalStyles";
import "what-input";
import { isClient, isProd } from "~/utils/common";
import { pageview } from "~/utils/fbpixel";

import { AuthGuard } from "~/hocs/AuthGuard";
import { AuthProvider } from "~/contexts/JWTContext";

import "~/styles/fonts.css";
import "swiper/swiper-bundle.css";
import { IntercomController } from "~/components/IntercomController";
import { UTMSourceProvider } from "~/contexts/UTMSourceContext";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// GA 4
// if (!!process.env.NEXT_PUBLIC_ANALYTICS_ID_GA4) {
//   ReactGA.initialize(process.env.NEXT_PUBLIC_ANALYTICS_ID_GA4);
// }
// GA 3
// if (!!process.env.NEXT_PUBLIC_ANALYTICS_ID) {
//   ReactGA.initialize(process.env.NEXT_PUBLIC_ANALYTICS_ID, { debug: isDev ? true : false });
// }

if (isClient) smoothscroll.polyfill();

const defaultSeo: DefaultSeoProps = {
  title: "Restworld",
  titleTemplate: "%s | Restworld",
  description: "Restworld",
  twitter: {
    cardType: "summary_large_image",
  },
  openGraph: {
    type: "website",
    images: [{ url: "/og_restworld.png" }],
  },
};

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const [cookieConsent, setCookieConsent] = useState<boolean>(false);

  useEffect(() => {
    setCookieConsent(!!Cookies.get("cookie-consent"));
  }, [cookieConsent]);

  const handleRouteChange = useCallback(() => {
    pageview();
  }, []);

  useEffect(() => {
    if (!isProd || !Cookies.get("cookie-consent")) return;

    // This pageview only triggers the first time (it is important for Pixel to have real information)
    // console.log("Triggering a page view");
    // pageview();

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, cookieConsent, handleRouteChange]);

  // useEffect(() => {
  //   if (!!process.env.NEXT_PUBLIC_ANALYTICS_ID) ReactGA.send({ hitType: "pageview", page: router.asPath });
  // }, [router.asPath]);

  // useEffect(() => {
  //   if (!!process.env.NEXT_PUBLIC_ANALYTICS_ID) {
  //     ReactGA.initialize(process.env.NEXT_PUBLIC_ANALYTICS_ID, { debug: isDev ? true : false });
  //   }
  // }, []);

  return (
    <>
      <DefaultSeo {...defaultSeo} />
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
        <link rel="manifest" href="/favicons/site.webmanifest" />
        <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#5bbad5" />
        <link rel="shortcut icon" href="/favicons/favicon.ico" />
        <meta name="msapplication-TileColor" content="#603cba" />

        <meta name="msapplication-config" content="/favicons/browserconfig.xml" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="preload" href="/fonts/IBMPlexSans-Regular.ttf" as="font" type="font/ttf" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/praktikal-thin.otf" as="font" type="font/otf" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/praktikal-light.otf" as="font" type="font/otf" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/praktikal-regular.otf" as="font" type="font/otf" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/praktikal-medium.otf" as="font" type="font/otf" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/praktikal-semibold.otf" as="font" type="font/otf" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/praktikal-bold.otf" as="font" type="font/otf" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/praktikal-extrabold.otf" as="font" type="font/otf" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/praktikal-black.otf" as="font" type="font/otf" crossOrigin="anonymous" />

        {/* Intercom */}
        <script
          async
          dangerouslySetInnerHTML={{
            __html: `
              (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function")
              {ic('reattach_activator');ic('update',w.intercomSettings);}else{var 
              d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args)
              {i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');
              s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}';
              var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent)
              {w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
            `,
          }}
        />

        {/* Google GA4 */}
        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-XN98DMT91Q" /> */}
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://metrics.restworld.it/bjawahgu.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-K9GBDBW');`,
          }}
        />

        {/* SmartLook */}
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `(function(d) { var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0]; var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript'; c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c); })(document); smartlook('init', '7ee8a4f61456d89eb286ee885178f287da9c0d36', { region: 'eu' });`,
          }}
        /> */}
      </NextHead>
      <GlobalStyles />
      <NextNprogress color="#CFD71B" startPosition={0.3} stopDelayMs={200} height={5} />
      <AuthProvider>
        <UTMSourceProvider>
          <IntercomProvider>
            <IntercomController>
              <AuthGuard>
                <Component {...pageProps} />
              </AuthGuard>
            </IntercomController>
          </IntercomProvider>
        </UTMSourceProvider>
      </AuthProvider>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default App;
