import { useCallback, useContext, useEffect } from "react";
import { IntercomContext } from "~/contexts/intercomContext";

export const useIntercom = (initialVisibility: "visible" | "hidden" = "hidden") => {
  const { setVisibility, isReady } = useContext(IntercomContext);

  useEffect(() => {
    if (!isReady || initialVisibility === "visible") return;

    !!setVisibility && setVisibility(initialVisibility);
    !!setVisibility && setTimeout(() => setVisibility(initialVisibility), 1000);
    return () => setVisibility(true);
  }, [isReady, setVisibility, initialVisibility]);

  const show = useCallback(() => {
    !!setVisibility && setVisibility(true);
  }, [setVisibility]);

  const hide = useCallback(() => {
    !!setVisibility && setVisibility(false);
    !!setVisibility && setTimeout(() => setVisibility(false), 1000);
  }, [setVisibility]);

  return { show, hide, isReady };
};
