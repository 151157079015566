import { useRouter } from "next/router";
import { useEffect } from "react";
import { useIntercom } from "~/hooks/useIntercom";

function IntercomController({ children }: { children: JSX.Element }) {
  const router = useRouter();
  const { show, hide, isReady } = useIntercom("visible");

  useEffect(() => {
    if (!isReady) return;
    const pathArray = router.pathname.split("/").filter((path) => path !== "");
    if (pathArray.length > 1 && pathArray[0] === "employer") {
      hide();
      setTimeout(hide, 1000);
    } else if (pathArray.length > 0 && pathArray[0] === "horecanews-offerte") {
      hide();
      setTimeout(hide, 1000);
    } else {
      show();
    }
  }, [show, hide, router.pathname, isReady]);

  return children;
}

export { IntercomController };
