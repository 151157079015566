import { useCallback, useEffect, useState } from "react";
import { createContext, ReactNode } from "react";
import { useRouter } from "next/router";
import dayjs from "dayjs";
import { Cookies } from "react-cookie-consent";
import { WorkerData } from "types";
import { useWorkerData } from "~/hooks/useWorkerData";
import { getRequest } from "~/utils/api";
import useAuth from "~/hooks/useAuth";

const IntercomContext = createContext<any>(null);

function IntercomProvider({ children }: { children: ReactNode }) {
  const { workerData, fetchWorkerData } = useWorkerData();
  const { user: userData, jwt } = useAuth();
  const [isReady, setIsReady] = useState<boolean>(false);

  const intercomInitializer = useCallback(() => {
    if (!userData) {
      window.Intercom("boot", {
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? "",
        // name: "Amic*",
      });
    } else {
      const intercomData: any = {
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? "",
        user_id: userData.id,
        created_at: dayjs(userData.inserted_at).unix(),
      };
      if (userData.type === "worker") initWorkerIntercom(intercomData);
      if (userData.type === "employer") initEmployerIntercom(intercomData);
    }
  }, [userData]);

  useEffect(() => {
    // if (isReady) return;
    intercomInitializer();
    setIsReady(true);
  }, [intercomInitializer]);

  const initWorkerIntercom = async (intercomData: any) => {
    interface CustomIntercomInterface extends Intercom_.IntercomSettings {
      surname: string | null;
      birthdate: string | null;
      experience: string | null;
      domain_interest: string | null;
      job_specialization: string | null;
    }

    !!fetchWorkerData && fetchWorkerData();

    intercomData.name = userData?.name;
    intercomData.email = userData?.email;
    intercomData.phone = workerData?.user?.phone_number;
    intercomData.surname = workerData?.surname;
    intercomData.birthdate = workerData?.birthdate;
    intercomData.experience = workerData?.experience_var?.label;
    intercomData.domain_interest = workerData?.research_info?.domain_interest_var?.label;
    intercomData.job_specialization = workerData?.research_info?.occupations[0]?.label_it?.label;

    if (!!Cookies.get("cookie-consent")) window.Intercom("boot", intercomData as CustomIntercomInterface);
  };

  const initEmployerIntercom = async (intercomData: any) => {
    interface CustomIntercomInterface extends Intercom_.IntercomSettings {
      surname: string | null;
      business_name: string | null;
    }

    try {
      const res = await getRequest(`/employers/restaurants?limit=10&offset=0`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      const restaurant = res.data?.data
        .map((r: any) => r.restaurant)
        .find((r: WorkerData) => "referent_user_contacts" in r);

      intercomData.name = restaurant?.referent_user_contacts?.at(0)?.user?.name;
      intercomData.surname = restaurant?.referent_user_contacts?.at(0)?.user?.surname;
      intercomData.email = restaurant?.referent_user_contacts?.at(0)?.user?.email;
      intercomData.phone = restaurant?.referent_user_contacts?.at(0)?.user?.phone_number;
      intercomData.business_name = userData?.business_name;

      window.Intercom("boot", intercomData as CustomIntercomInterface);
    } catch (e) {
      console.error("restaurants error: ", e);
    }
  };

  const setVisibility = useCallback(
    (isVisible: boolean) => {
      isVisible ? intercomInitializer() : window.Intercom("shutdown");
    },
    [intercomInitializer]
  );

  return <IntercomContext.Provider value={{ setVisibility, isReady }}>{children}</IntercomContext.Provider>;
}

export { IntercomContext, IntercomProvider };
