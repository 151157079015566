import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { createContext, ReactNode } from "react";

const UTMSourceContext = createContext<{
  utmSource: string | null;
}>({ utmSource: null });

function UTMSourceProvider({ children }: { children: ReactNode }) {
  const router = useRouter();
  const [utmSource, setUtmSource] = useState<string | null>(null);

  useEffect(() => {
    if (!router.query.utm_source || !(typeof router.query.utm_source === "string")) return;
    setUtmSource(router.query.utm_source);
  }, [router.query.utm_source]);

  const saveUTMSource = useCallback(() => {
    if (!router.query.utm_source || !(typeof router.query.utm_source === "string")) return;
    setUtmSource(router.query.utm_source);
  }, [router.query.utm_source, setUtmSource]);

  useEffect(() => {
    if (!router.events) return;

    router.events.on("routeChangeStart", saveUTMSource);
    return () => {
      router.events.off("routeChangeStart", saveUTMSource);
    };
  }, [router.events, saveUTMSource]);

  return <UTMSourceContext.Provider value={{ utmSource }}>{children}</UTMSourceContext.Provider>;
}

export { UTMSourceContext, UTMSourceProvider };
